import React from 'react';
import ResetPassword from '@components/Pages/Account/ResetPassword';
import OnlyAnonymous from '@components/Auth/OnlyAnonymous';

const ResetPasswordPage = () => (
  <OnlyAnonymous redirectTo={'/account/settings'}>
    <ResetPassword />
  </OnlyAnonymous>
);
export default ResetPasswordPage;
