import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import Title from '@components/Title';
import { OneColumn } from '@components/Layouts/OneColumn';
import { useTranslation } from '@external/react-i18next';
import TextField from '@components/Formik/TextField';
import { Formik, Form } from 'formik';
import * as yup from 'yup';
import {
  schemaField,
  isRequired,
  isEmail,
} from '@components/Formik/validation/schemaDefinitions';
import { Button } from '@components/Button';
import { useMutation, gql } from '@apollo/client';
import {
  SendPasswordResetMessageMutationVariables,
  SendPasswordResetMessageMutation,
} from '@typings/operations';

const useSendPasswordResetMessageMutation = () =>
  useMutation<
    SendPasswordResetMessageMutation,
    SendPasswordResetMessageMutationVariables
  >(gql`
    mutation sendPasswordResetMessage($email: String!, $langcode: String!) {
      sendPasswordResetMessage(email: $email, langcode: $langcode)
    }
  `);

interface Props extends RouteComponentProps {}
const ResetPassword: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  const [showSuccess, setShowSuccess] = useState(false);
  const [sendPasswordResetMessage] = useSendPasswordResetMessageMutation();

  const handleSubmit = async ({ email }: { email: string }) => {
    await sendPasswordResetMessage({
      variables: {
        email,
        langcode: i18n.language,
      },
    });
    setShowSuccess(true);
  };

  return (
    <OneColumn className="mb-20">
      <Title>{t('reset-password.title', 'Forgot Password')}</Title>
      {showSuccess ? (
        <p>
          {t(
            'reset-password.success',
            'Steps to reset your password have been sent to your email.'
          )}
        </p>
      ) : (
        <>
          <p>
            {t(
              'reset-password.info',
              'Please enter your sign-in email to reset your password. To retrieve your sign-in email, please <a href="/en/contact">contact us by email or phone</a>.'
            )}
          </p>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={handleSubmit}
            validationSchema={yup.object().shape({
              email: schemaField(t, yup.string(), isRequired, isEmail),
            })}
          >
            {({ isSubmitting }) => (
              <Form>
                <TextField
                  name="email"
                  label={t('reset-password.form.email-label', 'Email')}
                  required
                />
                <Button disabled={isSubmitting}>
                  {t('reset-password.form.submit-label', 'Submit')}
                </Button>
              </Form>
            )}
          </Formik>
        </>
      )}
    </OneColumn>
  );
};

export default ResetPassword;
